import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import "../styles/news.scss"
import "../styles/caseStudy.scss"
import NewsDetails from "../components/NewsDetails"
import CaseStudeiesTopSection from '../components/CaseStudeiesTopSection'

const News = ({ data }) => {
  return (
    <div className="CaseStudeies news">
      <Layout pageTitle={"Visiomate - News about Visiomate"}
      descrip = {"Stay up-to-date with the latest news and announcements from Visiomate. Learn about our company milestones, product launches, and industry insights."}>
        <CaseStudeiesTopSection
          path='news'
          headerData={{
            title: "Visiomate and UMT have signed an MOU to equip young students for industrial difficulties and to support talent development and meet the needs of the actual world of business.",
            peragraph: ""
          }} data={data} />
        <NewsDetails
          data={{
            title: "See What's Going on in Visiomate",
            News: [
              {
                heading: "Successfully Delivered and Complete CRM Solution for Big Jerry's Fencing Company.",
                description:
                  "Date: 15 Feb, 2024",
              },
              {
                heading: "YAZO a POS product Launched in Autralia, ready to take on new customers",
                description:
                  "Date: 10 Oct, 2023",
              },
              {
                heading: "Visioedge is Launched in Pakistan, a localized cost effective ERP solution based on Odoo Community",
                description:
                  "Date: 12 Feb, 2024",
              },
              {
                heading: "Learning circles, a HR initiatives for employee's knowledge enhancement",
                description:
                  "A weekly event managed by visiomate HR team.",
              },
              {
                heading: "Comming Soon, A robust bootcampe for anyone who want's to be a successfull Mobile Developer",
                description:
                  "Coming Soon in start of 2nd quarter of 2024",
              },
              {
                heading: "Moved to a better and bigger office, we are committed to provide the working environement",
                description:
                  "Date: 10 Jan, 2023",
              },
            ],
          }} dataforImages={data} />
      </Layout>
    </div>
  )
}

export default News
export const query = graphql`
  query NewsImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsHeaderImg: file(relativePath: { eq: "news/UMT_News.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(relativePath: { eq: "news/UMT_News.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard1: file(relativePath: { eq: "news/Bigjerryfencing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard2: file(relativePath: { eq: "news/yazoPOS1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard3: file(relativePath: { eq: "news/visioedge_card.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard4: file(relativePath: { eq: "news/Circle.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard5: file(relativePath: { eq: "news/bootcamp.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    newsCard6: file(relativePath: { eq: "news/good_environement4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;


